<template>
<div>
  <div class="blog-page-left">
    <div class="blog-image">
      <img :src="require('../../assets/img/slider-2.jpeg')" alt="blog" />
    </div>
    <div class="blog-text single-blog-text">
      <div class="blog-meta">
        <p><i class="fa fa-user"></i> By Jamil Fares</p>
        <p><i class="fa fa-clock-o"></i> 14 Mar, 2023</p>
      </div>
      <h2>Corporation mergers and acquisition</h2>
      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elitSed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elitSed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem quet perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem</p>
      <img :src="require('../../assets/img/blog-1.jpeg')" alt="blog" />
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elitSed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.natus error sit voluptatem accusa nt ium dolorem que </p>
      <p>ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      <blockquote>Sed eleifend ornare turpis ac scelerisque. Donec in euismod erat. Cras luctus dapibus nibh, ac tincidunt magna semper sit amet.</blockquote>
      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      <p>ipsum dolor sit amet, consectetur adipiscing elitSed ut perspiciatis unde omnis iste natus error sit voluptatem accusa nt ium dolorem que laudantium totam rem aperiam.Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
    </div>
    <div class="post-share">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <div class="post-share-left">
            <h4>Tags :</h4>
            <p>
              <b-link>#blog</b-link>
            </p>
            <p>
              <b-link>#corporation</b-link>
            </p>
            <p>
              <b-link>#Security</b-link>
            </p>
          </div>
        </div>
        <div class="col-md-6 col-sm-6">
          <div class="post-share-right">
            <h4>Share :</h4>
            <ul>
              <li><b-link><i class="fa fa-facebook"></i></b-link></li>
              <li><b-link><i class="fa fa-twitter"></i></b-link></li>
              <li><b-link><i class="fa fa-linkedin"></i></b-link></li>
              <li><b-link><i class="fa fa-google-plus"></i></b-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
<!--  &lt;!&ndash; Comments Area Component&ndash;&gt;-->
<!--    <comments/>-->
  </div>
</div>
</template>

<script>
// import Comments from "./Comments";
export default {
  name: "Details"
  // components: {Comments}
}
</script>

<style scoped>

</style>